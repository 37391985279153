<template>
	<v-dialog v-if="text" v-model="dialog" width="400">	
		<template v-slot:activator="{on}">	
			<v-chip class="mt-4" small dark :color="color"> 
				<v-icon class="ml-1 mr-3" left small v-on="on"> {{icon}} </v-icon>
				{{text}}
			</v-chip>	
		</template>
		<UserAssessmentRole :role="role" @update="updateRole"></UserAssessmentRole>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import UserAssessmentRole from "@/components/admin/panels/UserAssessmentRole"
	export default {
		name: "UserAssessmentButton", 
		props: {
			text: {},
			user: {},
			assessment: {}
		},
		data: () => {
			return {				
				dialog: false,

			}
		},
		components: {
			UserAssessmentRole
		},
		computed: {
			// ...Vuex.mapState({
			// 	organisation(state){
			// 		return state.organisations[this.item.value]
			// 	},
			// }),
			// assessmentId(){
			// 	return this.organisation.assessment;
			// },
			assessmentObject(){
				if( !this.assessment ){
					return;
				}
				return this.$store.state.assessments[this.assessment];
			},
			role(){
				if( !this.assessment ){
					return false;
				}
				if(!this.assessmentObject.users) {
					return false;
				}
				return this.assessmentObject.users[this.user] || false
			},
			color(){
				if( this.role ){
					return "primary"
				}
				else{
					return "grey"
				}
			}, 
			icon(){
				if( !this.role ){
					return "mdi-circle"
				}
				if( this.role == "leadRespondent" ){
					return "mdi-account-star"
				}
				else if( this.role == "dataUser" ){
					return "mdi-chart-bar"
				}
				return "mdi-circle"

			}
		},
		methods: {
			updateRole(role){

				this.$store.dispatch("assessments/updateUsers", {users: {[this.user]: role}, assessment: this.assessment })

				this.dialog = false;
			}
		},
		created(){
		}

	}
// </script>"