<template>
	<v-card>
		<v-card-title>{{mwtr.EditUserAssignment}}</v-card-title>
		<v-card-text>
			<v-radio-group v-model="type">
				<v-radio class="mt-0" value="leadRespondent" :label="mwtr.LeadRespondent"></v-radio>
				<v-radio class="mt-0" value="dataUser" :label="mwtr.DataUser"></v-radio>
			</v-radio-group>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "UserOrganisationRole", 
		props: {
			role: { }
		},
		data: () => {
			return {	
				type: ""			
			}
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		methods: {
			save(){
				this.$emit("update", this.type )
			}
		},
		created(){
			this.type = this.role			
		}

	}
// </script>"