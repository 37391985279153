<template>
	<v-sheet flat>	
		<v-toolbar flat>
			<v-tabs v-model="tab" class="ml-3" color="grey">
				<v-tab>{{mwtr.OrganisationNavItem}}</v-tab>
				<v-tab v-if="id">{{mwtr.UsersNavItem}}</v-tab>
				<v-tab v-if="id">{{mwtr.AssessmentNavItem}}</v-tab>
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tab">
			<v-tab-item>
				<v-card flat class="pa-5">
					<GeneralForm :id="id" collection="organisations" :data="organisation">
						<template v-slot:formContent>
							<OrganisationForm :data="organisation"></OrganisationForm>
						</template>
					</GeneralForm>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card flat class="pa-5">
					<AssessmentUsers :id="organisation.assessment"></AssessmentUsers>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card flat class="pa-5">
					<AssessmentData :id="organisation.assessment" :name="organisation.name"></AssessmentData>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	
	import GeneralForm from "@/components/admin/forms/GeneralForm"
	import OrganisationForm from "@/components/admin/forms/OrganisationForm"
	import AssessmentUsers from "@/components/admin/panels/AssessmentUsers"
	import AssessmentData from "@/components/admin/panels/AssessmentData"
	export default {
		name: "EditOrganisation", 
		props: {
			id: {}
		},
		data: () => {
			return {
				tab: 0,
				fields: {
					name: "", 
					type: "", 
					address: {
						streetAddress: "",
						city: "",
						state: "",
						postcode: ""
					}, 
					beds: "",
					universityHospital: "", 
					owner: "", 
					departments: []
				}
			}
		},
		computed: {
			organisation(){
				var organisation = this.$store.state.organisations[this.id]
				return organisation || this.fields ;
			}
		},
		components: {
			GeneralForm, 
			OrganisationForm,
			AssessmentUsers,
			AssessmentData
			// OrganisationUsers
			// DeleteButton
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},
		created(){
			if( this.id ){			
				this.$store.dispatch("organisations/fetchOne", this.id)
			}
		}

	}
// </script>"