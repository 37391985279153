<template>
	<div>
		<v-data-table :items="map" :headers="columns"></v-data-table>
		<Export :rows="map" :name="`${name}.xls`"></Export>	
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Export from "@/components/admin/panels/Export"

	export default {
		name: "AssessmentData", 
		props: {
			id: {}, 
			name: {}
		},
		data: () => {
			return {
			}
		},
		components: {
			Export
		},
		computed: {
			...Vuex.mapState({
				assessment(state){ 
					return state.assessments[this.id]
				},
				sections: state => state.sections,
				goalGroups: state => state.goalGroups,
				goals: state => state.goals,
				questions: state => state.questions
			}),
			columns(){
				var cols = [
					{text: "Section", value:"section" },
					{text: "SID", value:"section_igid" },
					{text: "Status", value: "status"},
					{text: "GoalGroup", value:"goalgroup" },
					{text: "Goal Group Id", value:"goalgroup_igid" },
					{text: "Goal", value:"goal" },
				]

				var questions = this.questions;
				if( questions ){
					Object.keys(questions).forEach( q => {
						cols.push({ value: q, text: questions[q].title })
					})
				}
				return cols
			},
			map(){
				var self = this; 
				var flat = [];
				if( !self.sections ){
					return;
				}
				Object.keys(self.sections).forEach( sk => {
					var s = self.sections[sk]
					var section = s.name;
					var section_igid = s.igid;
					if( !s.parts ){
						return;
					}
					Object.keys(s.parts).forEach( pk => {
						var p = s.parts[pk]
						var gg = self.goalGroups[p.goalGroup];
						var goalgroup = gg.name;
						var goalgroup_igid = gg.igid;
						p.goals.forEach( g => {						
							var goal = self.goals[g].name;
							var row = {
								section,
								section_igid,
								goalgroup,
								goalgroup_igid,
								goal
							}
							if( self.assessment.sections[sk] ){
								row.status = self.assessment.sections[sk].status;
								if( self.assessment.sections[sk].data ){
									var response = Object.values(self.assessment.sections[sk].data).find( d => {
										return d.part == pk && d.goal == g 
									} )
									if( response ){
										for( var key in response.data ){
											row[key] = response.data[key];
										}
									}
								}
							}
							flat.push( row );
						})

					})
				} )
				return flat;
			}
		},
		watch: {
	
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},
		created(){
			this.$store.dispatch("sections/fetchAll" );
			this.$store.dispatch("departments/fetchAll" );
			this.$store.dispatch("goals/fetchAll" );
			this.$store.dispatch("goalGroups/fetchAll" );		
			this.$store.dispatch("questions/fetchAll" );		
			if( this.id ){			
				this.$store.dispatch("assessments/fetchOne", this.id)
				this.$store.dispatch("assessments/fetchSections", this.id );
			}
		}
	}
// </script>"