<template>

	<v-select multiple chips removable-chips :return-object="false" :items="items" item-key="name" item-value="value" v-model="selected" :label="mwtr.DepartmentsNavItem">	
	</v-select>			
</template>

<style lang="less">
	
</style>

<script type="text/javascript">

	import Vuex from "vuex"
	export default {
		name: "OrganisationDepartments", 
		props: {
			data: {}
		},
		data: () => {
			return {
				selected: []
			}
		},
		computed: {
			...Vuex.mapState({
				departments: state => state.departments
			}), 
			items() {
				return this.mwutilities.itemsArray(this.departments)
			}

		}, 
		watch: {
			data: {
				immediate: true, 
				handler(){
					this.selected = this.data
				}
			},
			selected(){
				this.$emit("update", this.selected );
			}
		},
		created(){
			this.$store.dispatch("departments/fetchAll")
		}

	}
// </script>"