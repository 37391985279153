<template>
	<div>
		<v-autocomplete
			multiple
			v-model="selected"
			append-icon="mdi-plus-circle"
			:items="userItems"
			:label="mwtr.UsersNavItem"
			>
			<template v-slot:selection="data">
				<UserAssessmentButton :user="data.item.value" :text="data.item.text" :assessment="id"></UserAssessmentButton>
			</template>
			</v-autocomplete>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import UserAssessmentButton from "@/components/admin/forms/fields/UserAssessmentButton"

	export default {
		name: "AssessmentUsers", 
		props: {
			id: {}
		},
		data: () => {
			return {
				selected: []
			}
		},
		components: {
			UserAssessmentButton,
		},
		computed: {
			...Vuex.mapState({
				assessment(state){ 
					return state.assessments[this.id]
				},
				users: state => state.users
			}),
			userItems(){
				const self = this;
				if( !self.assessment ){
					return [];
				}
				return Object.keys(self.users).filter( user => {
					return self.users[user].organisations && self.users[user].organisations.indexOf(self.assessment.organisation) > -1 
				}).map( user => {
					return {value: user, text: self.users[user].displayName }
				} )
			}
		},
		watch: {
			assessment: {
				immediate: true,
				handler(value){
					if(!value){
						return;
					}
					if( !value.users ){
						return;
					}
					this.selected = Object.keys(value.users)
				}
			},	
			selected(nv, ov ){
				const self = this;
				if(!this.assessment.users){
					this.assessment.users = {};
				}
				if( ov && nv !== ov ){
					ov.forEach( o => {
						if( nv.indexOf( o ) < 0 ){
							self.$store.dispatch("assessments/deleteUser", 
								{
									user: o,
									assessment: self.id
								}
							)
						}
					})
					nv.forEach( n => {
						if( !self.assessment.users[n] ){
							self.$store.dispatch("assessments/updateUsers", 
								{
									users: {[n]: "user"},
									assessment: self.id
								}
							)
						}
					})
				}
			}, 
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},
		created(){
			this.$store.dispatch("users/fetchAll");
			if( this.id ){			
				this.$store.dispatch("assessments/fetchOne", this.id)
			}
		}
	}
// </script>"
