<template>
					<v-form>
						<v-row>
							<v-col>
								<v-text-field 	
									v-model="data.name" 
									:label="mwtr.OrganisationName">
								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea 
									v-model="data.address.streetAddress" 
									:label="mwtr.OrganisationAddress" :rows="4">
								</v-textarea>

							</v-col>
							<v-col>
								<template v-for="field in mwsettings.AddressFields">
									<v-component :key="field.value" :is="field.component" v-model="data.address[field.value]" v-bind="field.options" :label="mwtr[field.label]"></v-component>
								</template>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-text-field 
									v-model="data.owner" 
									:label="mwtr.OrganisationOwner">
								</v-text-field>

								<v-select :items="mwsettings.OrganisationTypes" 
									v-model="data.type" 
									:label="mwtr.OrganisationType">
								</v-select>
							</v-col>
							<v-col cols="3">
								<v-switch 
									v-model="data.universityHospital" 
									:label="mwtr.UniversityHospital">
								</v-switch>

							</v-col>
							<v-col cols="3">
								<v-text-field 
									v-model="data.beds" type="number" 
									:label="mwtr.OrganisationBedNumber">
								</v-text-field>
							</v-col>
						</v-row> 
						<v-row>
							<v-col>
								<OrganisationDepartments @update="setDepartments" :data="data.departments"></OrganisationDepartments>
							</v-col>
						</v-row>
					</v-form>
			

</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import OrganisationDepartments from "@/components/admin/panels/OrganisationDepartments"
	export default {
		name: "OrganisationForm", 
		props: {
			data: {}
		},
		data: () => {
			return {
			}
		},
		components: {
			OrganisationDepartments
		},
		methods: {
			setDepartments(data){
				this.data.departments = data;
			}
		}
	
	}
// </script>"